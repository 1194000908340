<template>
  <div class="Trial">
    <template v-if="productId === '25'">
      <img
        class="main_img pc"
        :src="rt + '/img/lp/trial-cv.jpg'"
        alt="初回お試し100円"
      />
      <img
        class="main_img sp"
        :src="rt + '/img/lp/trial-cv-sp.jpg'"
        alt="初回お試し100円"
      />
    </template>
    <template v-else-if="productId === '31'">
      <img
        class="main_img pc"
        :src="rt + '/img/lp/trial-cv-20220803.jpg'"
        alt="初回お試し100円"
      />
      <img
        class="main_img sp"
        :src="rt + '/img/lp/trial-cv-sp-20220803.jpg'"
        alt="初回お試し100円"
      />
    </template>

    <p class="txt_size">
      初めてアドタスカルをご契約いただく場合に限り、アドタスカルを初回100円(税込)でお試しできます。<br />
      <br />
      アドタスカルが提供するすべてのツールをご利用していただくことができます。<br />
      <br />
      自動更新決済の設定がされますが、ご契約後、アドタスカルの管理画面から自動更新設定の解除はいつでもできますので、実質的には自動更新の縛りはございません。<br />
      <br />
      自動更新される前に、自動更新される旨をご登録のメールアドレスと管理画面上でお知らせいたします。
    </p>

    <p>
      ※注意事項<br />
      ・初回お試しは、１法人または１事業主につき１回までとなります。２回目の申し込みの場合は差分の5,900ポイントの返却処理を行います。<br />
      ・ポイントの有効期限は通常1年間ですが、お試しのみ3ヶ月間となります。
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Trial",
  components: {},
  props: {
    productId: String,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["rt", "tools", "apMinValue"]),
  },
};
</script>

<style lang="scss" scoped>
.Trial {
  padding-bottom: 50px;
  @media screen and (max-width: $max-width) {
    padding-top: 30px;
  }

  .title {
    margin-top: 70px;
    @media screen and (max-width: $max-width) {
      margin-top: 50px;
    }
  }
}

img {
  @media screen and (max-width: $max-width) {
    width: 100%;
  }
}

.trial_plan {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 0;
  @media screen and (max-width: $max-width) {
    margin-bottom: 0;
  }
  .plan_name {
    font-weight: bold;
    font-size: 20px;
  }
  p:last-child {
    text-align: right;
  }
  table {
    border-collapse: collapse;
    th {
      border: 1px solid #ccc;
      padding: 10px 20px;
      font-size: 18px;
      background-color: #f4f4f4;
      background-color: #e6e6e6;
      color: #4d4d4d;
      @media screen and (max-width: $max-width) {
        padding: 8px 0;
        width: 60%;
      }
      span {
        @media screen and (max-width: $max-width) {
          font-size: 14px;
          font-weight: normal;
        }
      }
    }
    td {
      border: 1px solid #ccc;
      text-align: center;
      font-size: 18px;
      background-color: white;
      @media screen and (max-width: $max-width) {
        padding: 10px 0;
        border: none;
      }
      span {
        font-weight: bold;
        color: #f15e62;
      }
    }
    .title {
      // background-color: #e1ecf6;
      background-color: #f4d7d9;
      @media screen and (max-width: $max-width) {
        font-size: 22px;
      }
    }
    .title:nth-child(2) {
      padding: 0 180px;
    }
    .buy_btn {
      // background-color: #1c6db5;
      background-color: #f15e62;
      color: white;
      font-size: 15px;
      border-radius: 5px;
      padding: 10px 15px;
      @media screen and (max-width: $max-width) {
        display: block;
        margin-bottom: 60px;
        font-size: 18px;
      }
    }
    .border {
      @media screen and (max-width: $max-width) {
        border: 1px solid #ccc;
      }
    }
    .btn {
      padding: 15px;
      @media screen and (max-width: $max-width) {
        padding: 15px 0 0;
        // background-color: #F2F4F4;
        background-color: transparent;
      }
    }
  }
}

.b {
  font-weight: bold;
  @media screen and (max-width: $max-width) {
    border: none;
  }
}

.title {
  // margin-top: 0;
  @media screen and (max-width: $max-width) {
    margin-bottom: 30px;
    margin-top: 80px;
  }
}

.box_cover {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px dashed #ccc;
}
.box_cover:last-child {
  border-bottom: none;
}
.pointstyle {
  font-weight: bold;
  // color: #1c6db5;
  color: #f15e62;
  // margin: 0 10px;
}
.main_img {
  width: 100%;
  margin-bottom: 50px;
  margin-top: 0;
  padding-top: 0;
  @media screen and (max-width: $max-width) {
    padding-top: 0;
  }
}
.trial_btn {
  padding: 12px 50px;
  font-size: 25px;
  letter-spacing: 0.05em;
  // font-weight: bold;
  color: white;
  background-color: #f15e62;
  border-radius: 10px;
  margin: 80px auto 30px;
  display: block;
  width: 450px;
  text-align: center;
  @media screen and (max-width: $max-width) {
    width: 100%;
    box-sizing: border-box;
    font-size: 17px;
    padding: 12px 0;
    margin-top: 50px;
  }
}
.cyu-syaku {
  text-align: center;
  margin-bottom: 80px;
  @media screen and (max-width: $max-width) {
    margin-bottom: 0;
  }
  p {
    border-bottom: 1px solid #ccc;
    display: inline;
    padding-bottom: 3px;
  }
}
</style>
