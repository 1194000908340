<template>
  <div id="app">
    <Header :HeaderType="headerType" />
    <!-- <div v-show="headerType==1">
      <img class="main_img" :src="rt+'/img/lp/top_main_img.jpg'" alt="アドタスカルイメージ画像" />
    </div> -->
    <!-- <div v-show="headerType==2">
      <img class="main_img" :src="rt+'/img/lp/popup01_main.jpg'" alt="アドタスカルイメージ画像" />
    </div> -->
    <main>
      <router-view @headerType="HT" />
    </main>
    <Footer />
    <ToTopButton />
  </div>
</template>

<script>
import store from "@/store";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import ToTopButton from "@/components/ToTopButton.vue";
import $ from "jquery";

export default {
  name: "app",
  data() {
    return {
      rt: store.state.rt,
      headerType: 0,
      scrollY: 0,
      timer: null
    };
  },
  components: {
    Header,
    Footer,
    ToTopButton
  },
  methods: {
    HT(val) {
      this.headerType = val;
      console.log("headerType is ... " + this.headerType + " !!!");
    },
    toTop() {
      $("html, body").animate({ scrollTop: 0 });
    },
  },
};
</script>

<style lang="scss">
html {
  font-family: "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", sans-serif;
}
body {
  margin: 0;
  main {
    width: 100%;
    padding-top: 70px;
    @media screen and (max-width: $max-width) {
      padding-top: 50px;
    }
  }
}
.txt_size {
  font-size: 18px;
}
.wrap {
  width: 1100px;
  margin: 0 auto;
  // padding-top: 80px;
  @media screen and (max-width: $max-width) {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
  }
}
.center {
  text-align: center;
}
input[type="radio"] {
  cursor: pointer;
}
label {
  cursor: pointer;
}
.el-row {
  margin-bottom: 30px;
}
.info {
  .align {
    .el-textarea__inner {
      height: 75px;
    }
  }
}
.main_img {
  // padding-top: 70px;
}
h1 {
  text-align: center;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
  color: #4d4d4d;
  font-size: 35px;
  // padding: 10px 0 75px;
  margin-bottom: 75px;
  @media screen and (max-width: $max-width) {
    font-size: 25px;
    margin-bottom: 20px;
  }
  span {
    color: #1c6db5;
  }
}
.title {
  text-align: center;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
  color: #4d4d4d;
  font-size: 35px;
  font-weight: bold;
  margin-bottom: 70px;
  @media screen and (max-width: 767px) {
    font-size: 25px;
    margin-bottom: 40px;
  }
}

p {
  color: #4d4d4d;
  font-size: 16px;
  @media screen and (max-width: 767px) {
    line-height: 1.8em;
  }
}

//詳細情報などに使うテーブル
table.typeB {
  margin: 0 auto;
  width: 100%;
  border-collapse: collapse;
  color: #4d4d4d;
  tr {
    th,
    td {
      border: solid 1px #ebeef5;
      border-right: none;
      border-left: none;
    }
    th {
      width: 30%;
      padding: 20px 30px;
      text-align: center;
      background-color: #f0f0f0;
      border-top: solid 1px #ddd;
      border-bottom: solid 1px #ddd;
      .header-title {
        text-align: center;
      }
    }
    td {
      padding: 10px;
      .el-input {
        width: 90%;
        .el-input__inner {
          height: 34px;
        }
      }
    }
  }
  .el-row {
    margin: 0;
  }
}

.point_cover {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 50px;
  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
  .point_icon {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 110px;
    margin-block-start: 1.5em;
    @media screen and (max-width: 767px) {
      width: 90px;
    }
    img {
      width: 50px;
      @media screen and (max-width: 767px) {
        width: 40px;
      }
    }
    p {
      font-size: 28px;
      font-weight: bold;
      color: #1c6db5;
      letter-spacing: 0.05em;
      margin-block-start: 0;
      margin-block-end: 0;
    }
  }
  .point_txt_cover {
    width: 940px;
    // padding-left: 3em;
    box-sizing: border-box;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
    .point_title {
      font-size: 28px;
      font-weight: bold;
      color: #1c6db5;
      letter-spacing: 0.05em;
      @media screen and (max-width: 767px) {
        margin-block-start: 0;
      }
    }
  }
}
.bg_color {
  // background-color: #e1ecf6;
  // background-color: #F2F2F2;
  // background-color: #F7F5F5;
  // background-color: #F4F4F4;
  background-color: #f2f4f4;
}
.bg_color_blue {
  background-color: #e1ecf6;
  padding-top: 10px;
  padding-bottom: 50px;
  .title {
    margin-top: 0;
  }
}
a {
  text-decoration: none;
}
a:hover {
  opacity: 0.8;
}
.pc {
  display: block;
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.sp {
  display: none;
  @media screen and (max-width: 767px) {
    display: block;
  }
}
.bg_color {
  padding-top: 30px;
  @media screen and (max-width: 767px) {
    padding-top: 15px;
  }
}
.effect_img {
  width: 100%;
}
.small_title {
  p {
    display: inline;
    font-weight: bold;
    letter-spacing: 0.05em;
    background-color: #e1ecf6;
    padding: 10px 20px;
    border-radius: 5px 5px 0 0;
    color: #666666;
  }
}
.center {
  text-align: center;
}
.toTopButton {
  position: fixed;
  right: 5%;
  bottom: 5%;
  width: 50px;
  height: 50px;
  background-color: #1c6db5;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  border: solid 1px white;
  cursor: pointer;
  border-radius: 5px;

  //デフォルトで非表示にする
  // opacity: 0;
  // visibility: hidden;
}


//このクラスが付与されると表示する
.active{
  opacity: 1;
  visibility: visible;
}







button:focus {
  outline: 0;
}
.b {
  font-weight: bold;
}
.point_txt {
  font-size: 18px;
}

.up_cover {
  display: flex;
  justify-content: space-between;
  padding-bottom: 80px;
  @media screen and (max-width: $max-width) {
    flex-wrap: wrap;
    padding-bottom: 0;
  }
  .up {
    width: 530px;
    background-color: white;
    padding: 0 30px 10px;
    box-sizing: border-box;
    // position: absolute;
    @media screen and (max-width: $max-width) {
      margin-bottom: 50px;
    }
    .feature_title {
      font-size: 28px;
      font-weight: bold;
      margin-left: 40px;
      margin-top: -50px;
      margin-block-end: 10px;
      @media screen and (max-width: $max-width) {
        margin-left: 0;
        font-size: 24px;
      }
    }
    .up_icon {
      position: relative;
      top: -10px;
      left: -40px;
    }
  }
}

.about_cover {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 100px;
  @media screen and (max-width: $max-width) {
    flex-wrap: wrap;
    padding-bottom: 70px;
  }
  .about {
    width: 60%;
    @media screen and (max-width: $max-width) {
      width: 100%;
    }
    .about_title {
      font-size: 34px;
      font-weight: bold;
      @media screen and (max-width: $max-width) {
        font-size: 26px;
      }
    }
    .about_txt {
      line-height: 2em;
    }
  }
  img {
    width: 35%;
    @media screen and (max-width: $max-width) {
      width: 80%;
      margin: 30px auto 0;
    }
  }
}
.corporate-name {
  position: absolute;
  top: 85px;
  right: 40px;
  @media screen and (max-width: $max-width) {
    top: 70px;
    right: 20px;
  }
  p {
    color: white;
    @media screen and (max-width: $max-width) {
      font-size: 13px;
    }
  }
}

#cost {
  margin-top: -70px;
  padding-top: 70px;
}

.bg-color-blue {
  background-color: #eaf3f9;
  padding-top: 10px;
}

.point-space {
  margin-bottom: 80px;
}
.point-space-padding {
  padding-bottom: 35px;
}

.link-btn {
  width: 50%;
  margin: 0 auto;
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  background: linear-gradient(to bottom, #9acb3e, #018c48);
  border: 1px solid #018c48;
  border-radius: 5px;
  box-shadow: 0 3px 0 #006666;
  text-align: center;
  position: relative;
  padding: 20px 10px 20px;
  display: block;
  text-decoration: none;
  box-sizing: border-box;
  &:hover {
    opacity: 0.7;
  }
  @media screen and (max-width: $max-width) {
    width: 100%;
    font-size: 18px;
    padding: 10px 10px 8px;
  }
}
.st-reflection {
  position: relative;
  overflow: hidden;
  &::after {
    content: "";
    height: 100%;
    width: 30px;
    position: absolute;
    top: -180px;
    left: 0;
    background-color: #fff;
    opacity: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-animation: reflection 5s ease-in-out infinite;
    animation: reflection 5s ease-in-out infinite;
  }
}
@-webkit-keyframes reflection {
  0% {
    -webkit-transform: scale(0) rotate(45deg);
    transform: scale(0) rotate(45deg);
    opacity: 0;
  }

  80% {
    -webkit-transform: scale(0) rotate(45deg);
    transform: scale(0) rotate(45deg);
    opacity: 0.5;
  }

  81% {
    -webkit-transform: scale(4) rotate(45deg);
    transform: scale(4) rotate(45deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(50) rotate(45deg);
    transform: scale(50) rotate(45deg);
    opacity: 0;
  }
}

@keyframes reflection {
  0% {
    -webkit-transform: scale(0) rotate(45deg);
    transform: scale(0) rotate(45deg);
    opacity: 0;
  }

  80% {
    -webkit-transform: scale(0) rotate(45deg);
    transform: scale(0) rotate(45deg);
    opacity: 0.5;
  }

  81% {
    -webkit-transform: scale(4) rotate(45deg);
    transform: scale(4) rotate(45deg);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(50) rotate(45deg);
    transform: scale(50) rotate(45deg);
    opacity: 0;
  }
}
.red-bold {
  text-align: center;
  p {
    color: #f15e62;
    font-size: 32px;
    margin: 0;
    font-weight: bold;
    @media screen and (max-width: 767px) {
      font-size: 25px;
      line-height: 1.3em;
    }
  }
}
.el-dialog {
  .dialog-footer {
    .el-button {
      padding: 10px 15px !important;
    }
  }
}
</style>
